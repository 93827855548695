import { createQueryKeys } from '@lukemorales/query-key-factory';
import { type StaticDecode, Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SectionSorter as LibSectionSorter } from 'auto-converted/lib/SectionSorter';
import { LocString, Nullable } from 'schema/schema';
import { Sensitivity } from 'schema/sensitivity';
import { ImageUrl } from 'schema/shared';
import { httpChecked, queryClient } from 'util/fetch';
import { ProposalChildStatusSchema } from 'util/proposalStatus';

const referenceProjectInProposalsResponseSchema = Type.Object({
  _id: Type.String(),
  id: Type.String(),
  reference_project_id: Type.String(),
  company_project_id: Type.String(),
  company_id: Type.String(),
  project_name: LocString,
  project_name_anonymized: LocString,
  project_name_sensitivity: Sensitivity,
  company_customer_id: Type.String(),
  customer_name: LocString,
  customer_name_anonymized: LocString,
  order: Nullable(Type.Integer()),
  status: Nullable(ProposalChildStatusSchema),
  customer_image: Type.Object({
    small_thumb: ImageUrl,
  }),
});

const referenceProjectsInProposalsResponseChecker = TypeCompiler.Compile(
  Type.Array(referenceProjectInProposalsResponseSchema),
);

const referenceProjectAddedToProposalResponseSchema = Type.Object({
  _id: Type.String(),
  id: Type.String(),
  reference_project_id: Type.String(),
  company_project_id: Type.String(),
  project_name: LocString,
  customer_name: LocString,
  order: Nullable(Type.Integer()),
  status: Nullable(ProposalChildStatusSchema),
});

const referenceProjectAddedToProposalResponseChecker = TypeCompiler.Compile(
  referenceProjectAddedToProposalResponseSchema,
);

const referenceProjectsReorderedInProposalResponseChecker =
  TypeCompiler.Compile(
    Type.Array(referenceProjectAddedToProposalResponseSchema),
  );

export type ReferenceProject = StaticDecode<
  typeof referenceProjectInProposalsResponseSchema
>;

const queries = createQueryKeys('reference-projects-in-proposals', {
  proposal: (proposalId: string) => ({
    queryKey: [proposalId],
    queryFn: ({ signal }) =>
      httpChecked.get(
        `/api/v1/offers/${proposalId}/references`,
        referenceProjectsInProposalsResponseChecker,
        { signal },
      ),
  }),
});

export type AddReferenceProjectToProposalVariables = {
  proposalId: string;
  companyCustomerId: string;
  companyProjectId: string;
};

export const useAddReferenceProjectToProposal = () => {
  return useMutation({
    mutationKey: ['add-reference-project-to-proposal'],
    mutationFn: ({
      proposalId,
      companyCustomerId,
      companyProjectId,
    }: AddReferenceProjectToProposalVariables) =>
      httpChecked.post(
        `/api/v1/offers/${proposalId}/references`,
        referenceProjectAddedToProposalResponseChecker,
        {
          body: {
            reference: {
              company_customer_id: companyCustomerId,
              company_project_id: companyProjectId,
            },
          },
        },
      ),
    onSuccess: (_referenceProject, variables) =>
      queryClient.invalidateQueries(
        queries.proposal(variables.proposalId).queryKey,
      ),
  });
};

export type RemoveReferenceProjectFromProposalVariables = {
  proposalId: string;
  referenceId: string;
};

const removeReferenceProjectFromProposalResponseSchema = Type.Object({});
const removeReferenceProjectFromProposalResponseChecker = TypeCompiler.Compile(
  removeReferenceProjectFromProposalResponseSchema,
);

export const useRemoveReferenceProjectFromProposal = () => {
  return useMutation({
    mutationKey: ['remove-reference-project-from-proposal'],
    mutationFn: ({ proposalId, referenceId }) => {
      const url = `/api/v1/offers/${proposalId}/references/${referenceId}`;
      return httpChecked.delete(
        url,
        removeReferenceProjectFromProposalResponseChecker,
      );
    },
    onMutate: ({
      proposalId,
      referenceId,
    }: RemoveReferenceProjectFromProposalVariables) => {
      const { queryKey } = queries.proposal(proposalId);
      queryClient.cancelQueries(queryKey);
      const previousReferenceProjects =
        queryClient.getQueryData<ReferenceProject[]>(queryKey);
      const updatedReferenceProjects =
        previousReferenceProjects?.filter(
          (referenceProject) => referenceProject._id !== referenceId,
        ) ?? [];
      queryClient.setQueryData(queryKey, updatedReferenceProjects);
      return { previousReferenceProjects };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(
        queries.proposal(variables.proposalId).queryKey,
        context?.previousReferenceProjects,
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(
        queries.proposal(variables.proposalId).queryKey,
      );
    },
  });
};

export type ReorderReferenceProjectsInProposalVariables = {
  proposalId: string;
  movedReferenceId: string;
  droppedOnReferenceId: string;
  droppedAbove: boolean;
};

export const useReorderReferenceProjectsInProposal = () => {
  return useMutation({
    mutationKey: ['reorder-reference-projects-in-proposal'],
    mutationFn: ({
      proposalId,
      movedReferenceId,
      droppedOnReferenceId,
      droppedAbove,
    }: ReorderReferenceProjectsInProposalVariables) => {
      const { queryKey } = queries.proposal(proposalId);
      const references =
        queryClient.getQueryData<ReferenceProject[]>(queryKey) ?? [];
      const referenceSorter = new LibSectionSorter();
      const reorderedReferences = referenceSorter.reorder_sections(
        references.slice(0),
        movedReferenceId,
        droppedOnReferenceId,
        droppedAbove,
      );
      const body = {
        reordered: reorderedReferences.map((reference: ReferenceProject) => ({
          _id: reference._id,
          order: reference.order,
        })),
      };
      const url = `/api/v1/offers/${proposalId}/references`;
      return httpChecked.put(
        url,
        referenceProjectsReorderedInProposalResponseChecker,
        { body },
      );
    },
    onMutate: ({
      proposalId,
      movedReferenceId,
      droppedOnReferenceId,
      droppedAbove,
    }: ReorderReferenceProjectsInProposalVariables) => {
      const { queryKey } = queries.proposal(proposalId);
      queryClient.cancelQueries(queryKey);
      const previousReferences =
        queryClient.getQueryData<ReferenceProject[]>(queryKey);
      const referenceSorter = new LibSectionSorter();
      const reorderedReferences = previousReferences
        ? referenceSorter.reorder_sections(
            previousReferences.slice(0),
            movedReferenceId,
            droppedOnReferenceId,
            droppedAbove,
          )
        : [];

      queryClient.setQueryData(queryKey, reorderedReferences);
      return { previousReferences };
    },
    onError: (_err, variables, context) => {
      queryClient.setQueryData(
        queries.proposal(variables.proposalId).queryKey,
        context?.previousReferences,
      );
    },
    onSettled: (_references, _error, variables) => {
      queryClient.invalidateQueries(
        queries.proposal(variables.proposalId).queryKey,
      );
    },
  });
};

export const invalidateReferenceProjectsInProposalsData = async (
  proposalId: string,
) => {
  await queryClient.invalidateQueries(queries.proposal(proposalId).queryKey);
};

export function prefetchReferenceProjectsInProposal(proposalId: string) {
  return queryClient.prefetchQuery({
    ...queries.proposal(proposalId),
    staleTime: 60_000,
  });
}

export const useReferenceProjectsInProposal = (proposalId: string | false) => {
  const { data: references, isLoading } = useQuery({
    ...queries.proposal(proposalId || ''),
    staleTime: 60_000,
    keepPreviousData: true,
    enabled: proposalId !== false,
  });

  return {
    references: references ?? [],
    isLoading,
  };
};
