import _ from "underscore";
export class SectionSorter {
  reorder_sections(
    sections: any[],
    moved_section_id: string,
    dropped_on_section_id: string,
    dropped_above: any,
    orderByField = "order",
  ) {
    const moved_section = _.find(sections, (section) => {
      return section._id === moved_section_id;
    });
    const without_moved_section = _.reject(sections, (section) => {
      return section._id === moved_section_id;
    });
    const sorted_sections = _.sortBy(without_moved_section, orderByField);
    const dropped_on_section_index = _.findIndex(sorted_sections, (section) => {
      return section._id === dropped_on_section_id;
    });
    const insert_at_index = dropped_above
      ? dropped_on_section_index
      : dropped_on_section_index + 1;
    const copy = sorted_sections.slice(0);
    copy.splice(insert_at_index, 0, moved_section);
    return this.update_sections_with_new_order(copy, orderByField);
  }

  sortable_dates(section: any) {
    // Convert all the dates to strings (in the case of a month with one letter eg. 1 we convert it to 01)
    // Combine all the numbers to one large number with equal amounts of digits, using year > year month > month > id
    // Convert the cv id to a number and pick the 5 last letters, to avoid difference when sorting two equal numbers
    // Sort the numbers from lowest to highest
    const id_to_number = this.hash_from_string(section._id)
      .toString()
      .slice(-5);
    const sortable_dates = `${section.year_to || 3000}${
      section.year_from || 3000
    }${section.month_to?.padStart(2, "0") || 12}${
      section.month_from?.padStart(2, "0") || 12
    }${id_to_number}`;
    return Number(sortable_dates);
  }

  hash_from_string(str) {
    return str
      .split("")
      .reduce(
        (prevHash, currVal) =>
          ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
        0,
      );
  }

  sortable_from_dates(section: any) {
    const id_to_number = this.hash_from_string(section._id)
      .toString()
      .slice(-5);
    const sortable_from_dates = `${section.year || 3000}${
      section.month?.padStart(2, "0") || 12
    }${id_to_number}`;
    return Number(sortable_from_dates);
  }

  order_by_dates(sections: any, from_dates_only: boolean) {
    if (from_dates_only) {
      return this.order_by_from_dates(sections);
    }
    const sorted_sections = sections.sort(
      (a, b) => this.sortable_dates(a) - this.sortable_dates(b),
    );
    return this.update_sections_with_new_order(
      sorted_sections.reverse(),
      "order",
    );
  }

  order_by_from_dates(sections: any) {
    const sorted_sections = sections.sort(
      (a, b) => this.sortable_from_dates(a) - this.sortable_from_dates(b),
    );
    return this.update_sections_with_new_order(
      sorted_sections.reverse(),
      "order",
    );
  }

  update_sections_with_new_order(sorted_sections: any, orderBy: string) {
    return sorted_sections.map((section, index) => {
      section[orderBy] = index;
      return section;
    });
  }
}
