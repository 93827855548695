import { createQueryKeys } from '@lukemorales/query-key-factory';
import { type StaticDecode, Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import { useMutation, useQuery } from '@tanstack/react-query';
import _ from 'underscore';
import { SectionSorter as LibSectionSorter } from 'auto-converted/lib/SectionSorter';
import { LocString, Nullable } from 'schema/schema';
import { httpChecked, queryClient } from 'util/fetch';
import { ProposalChildStatusSchema } from '../util/proposalStatus';

const NullableImageUrl = Type.Object({
  url: Nullable(Type.String()),
});

const imageSchema = Type.Object({
  url: Nullable(Type.String()),
  fit_thumb: NullableImageUrl,
  large: NullableImageUrl,
  small_thumb: NullableImageUrl,
  thumb: NullableImageUrl,
});

const cvInProposalsResponseSchema = Type.Object({
  user_id: Type.String(),
  is_external: Type.Boolean(),
  image: imageSchema,
  name: Type.String(),
  name_multilang: LocString,
  updated_at: Type.String(),
  owner_updated_at: Nullable(Type.String()),
  title: Nullable(Type.String()),
  titles: LocString,
  telephone: Nullable(Type.String()),
  email: Type.String(),
  office_id: Nullable(Type.String()),
  custom_tag_ids: Type.Array(Type.String()),
  country_id: Nullable(Type.String()),
  navn: Type.String(),
  is_deactivated: Type.Boolean(),
  bruker_id: Type.String(),
  company_id: Type.String(),
  _id: Type.String(),
  id: Type.String(),
  updated_ago: Type.String(),
  default_word_template_id: Nullable(Type.String()),
  default_word_json_template_id: Nullable(Type.String()),
  default_ppt_template_id: Nullable(Type.String()),
  default_indesign_template_id: Nullable(Type.String()),
  country_code: Type.String(),
  language_code: Type.String(),
  language_codes: Type.Array(Type.String()),
  template_document_type: Type.String(),
  order: Nullable(Type.Integer()),
  status: Nullable(ProposalChildStatusSchema),
});

const cvsInProposalsResponseChecker = TypeCompiler.Compile(
  Type.Array(cvInProposalsResponseSchema),
);

const cvAddedToProposalResponseSchema = Type.Object({
  id: Type.String(),
  name: Type.String(),
  name_multilang: LocString,
  title: LocString,
  telephone: Nullable(Type.String()),
  landline: Nullable(Type.String()),
  twitter: Nullable(Type.String()),
  level: Nullable(Type.String()),
  born_year: Nullable(Type.Integer()),
  nationality: LocString,
  place_of_residence: LocString,
  order: Nullable(Type.Integer()),
  version: Nullable(Type.Integer()),
  user_id: Type.String(),
  proposal_id: Type.String(),
  created_at: Type.String(),
  updated_at: Type.String(),
  status: Nullable(Type.String()),
  uncategorized_technology_id: Nullable(Type.String()),
});

const cvAddedToProposalResponseChecker = TypeCompiler.Compile(
  cvAddedToProposalResponseSchema,
);

const cvsReorderedInProposalResponseChecker = TypeCompiler.Compile(
  Type.Array(cvAddedToProposalResponseSchema),
);

const proposalsWithUserResponseSchema = Type.Object({
  id: Type.String(),
  name: Type.String(),
  has_user_cv: Type.Boolean(),
});

const proposalsWithUserResponseChecker = TypeCompiler.Compile(
  Type.Array(proposalsWithUserResponseSchema),
);

export type ProposalWithUser = StaticDecode<
  typeof proposalsWithUserResponseSchema
>;
export type Cv = StaticDecode<typeof cvInProposalsResponseSchema>;

export type CvAddedToProposalResponse = StaticDecode<
  typeof cvAddedToProposalResponseSchema
>;

const queries = createQueryKeys('cvs-in-proposals', {
  proposal: (proposalId: string) => ({
    queryKey: [proposalId],
    queryFn: ({ signal }) =>
      httpChecked.get(
        `/api/v1/offers/${proposalId}/cvs`,
        cvsInProposalsResponseChecker,
        { signal },
      ),
  }),
  proposalsWithUser: (userId: string) => {
    return {
      queryKey: [userId],
      queryFn: ({ signal }) =>
        httpChecked.get(
          `/proposals-with-user/${userId}`,
          proposalsWithUserResponseChecker,
          { signal },
        ),
    };
  },
});

export const useProposalsWithUser = (userId: string | undefined) => {
  const { data: proposals, isLoading } = useQuery({
    ...queries.proposalsWithUser(userId ?? ''),
    staleTime: 60_000,
    keepPreviousData: true,
    enabled: userId !== undefined,
  });

  return {
    proposals: proposals
      ? _.sortBy(proposals, (p) => p.name.toLowerCase())
      : [],
    isLoading,
  };
};

export type AddCvToProposalVariables = {
  proposalId: string;
  cvId: string;
  userId?: string | undefined;
};

export const useAddCvToProposal = () => {
  return useMutation({
    mutationKey: ['add-cv-to-proposal'],
    mutationFn: ({
      proposalId,
      cvId,
      userId: _userId,
    }: AddCvToProposalVariables) =>
      httpChecked.post(
        `/api/v1/offers/${proposalId}/cvs`,
        cvAddedToProposalResponseChecker,
        {
          body: {
            cv: {
              cv_id: cvId,
            },
          },
        },
      ),
    onSuccess: (_cv, variables) => {
      // This endpoint doesn't return a full Cv, so we can't just update the cache with the response
      queryClient.invalidateQueries(queries.proposal(variables.proposalId));
      if (variables.userId)
        queryClient.invalidateQueries(
          queries.proposalsWithUser(variables.userId),
        );
    },
  });
};

export type RemoveCvFromProposalVariables = {
  proposalId: string;
  cvId: string;
};

const removeCvFromProposalResponseSchema = Type.Object({});
const removeCvFromProposalResponseChecker = TypeCompiler.Compile(
  removeCvFromProposalResponseSchema,
);

export const useRemoveCvFromProposal = () => {
  return useMutation({
    mutationKey: ['remove-cv-from-proposal'],
    mutationFn: ({ proposalId, cvId }) => {
      const url = `/api/v1/offers/${proposalId}/cvs/${cvId}`;
      return httpChecked.delete(url, removeCvFromProposalResponseChecker);
    },
    onMutate: ({ proposalId, cvId }: RemoveCvFromProposalVariables) => {
      const queryKey = queries.proposal(proposalId).queryKey;
      queryClient.cancelQueries(queryKey);
      const previousCvs = queryClient.getQueryData<Cv[]>(queryKey);
      const updatedCvs = previousCvs?.filter((cv) => cv._id !== cvId) ?? [];
      queryClient.setQueryData(queryKey, updatedCvs);
      return { previousCvs };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(
        queries.proposal(variables.proposalId).queryKey,
        context?.previousCvs,
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(
        queries.proposal(variables.proposalId).queryKey,
      );
    },
  });
};

export type ReorderCvsInProposalVariables = {
  proposalId: string;
  movedCvId: string;
  droppedOnCvId: string;
  droppedAbove: boolean;
};

export const useReorderCvsInProposal = () => {
  return useMutation({
    mutationKey: ['reorder-cvs-in-proposal'],
    mutationFn: ({
      proposalId,
      movedCvId,
      droppedOnCvId,
      droppedAbove,
    }: ReorderCvsInProposalVariables) => {
      const queryKey = queries.proposal(proposalId).queryKey;
      const cvs = queryClient.getQueryData<Cv[]>(queryKey) ?? [];
      const cvSorter = new LibSectionSorter();
      const reorderedCvs = cvSorter.reorder_sections(
        cvs.slice(0),
        movedCvId,
        droppedOnCvId,
        droppedAbove,
      );
      const body = {
        reordered: reorderedCvs.map((cv: Cv) => ({
          _id: cv._id,
          order: cv.order,
        })),
      };
      const url = `/api/v1/offers/${proposalId}/cvs`;
      return httpChecked.put(url, cvsReorderedInProposalResponseChecker, {
        body,
      });
    },
    onMutate: ({
      proposalId,
      movedCvId,
      droppedOnCvId,
      droppedAbove,
    }: ReorderCvsInProposalVariables) => {
      const queryKey = queries.proposal(proposalId).queryKey;
      queryClient.cancelQueries(queryKey);
      const previousCvs = queryClient.getQueryData<Cv[]>(queryKey);
      const cvSorter = new LibSectionSorter();
      const reorderedCvs = previousCvs
        ? cvSorter.reorder_sections(
            previousCvs.slice(0),
            movedCvId,
            droppedOnCvId,
            droppedAbove,
          )
        : [];

      queryClient.setQueryData(queryKey, reorderedCvs);
      return { previousCvs };
    },
    onError: (_err, variables, context) => {
      queryClient.setQueryData(
        queries.proposal(variables.proposalId).queryKey,
        context?.previousCvs,
      );
    },
    onSettled: (_cvs, _error, variables) => {
      queryClient.invalidateQueries(
        queries.proposal(variables.proposalId).queryKey,
      );
    },
  });
};

export const invalidateCvsInProposalsData = async (proposalId: string) => {
  await queryClient.invalidateQueries(queries.proposal(proposalId).queryKey);
};

export const prefetchCvsInProposalsData = async (proposalId: string) => {
  await queryClient.prefetchQuery({
    ...queries.proposal(proposalId),
    staleTime: 60_000,
  });
};

export const useCvsInProposal = (proposalId: string | false) => {
  const { data: cvs, isLoading } = useQuery({
    ...queries.proposal(proposalId || ''),
    staleTime: 60_000,
    keepPreviousData: true,
    enabled: proposalId !== false,
  });

  return {
    cvs: cvs ?? [],
    isLoading,
  };
};
