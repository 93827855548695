import type * as React from 'react';
import {
  buttons,
  container,
  header,
  headingAndSupportingText,
  paragraph,
  wrapper,
} from './styles.css';

export const Wrapper: React.VFC<{
  heading: string;
  description: string;
  buttons: React.ReactNode;
  icon: React.ReactNode;
  iconPosition?: 'above' | 'below';
}> = ({
  heading,
  description,
  buttons: buttonElements,
  icon,
  iconPosition = 'above',
}) => (
  <main className={wrapper}>
    <div className={container({ iconPosition })}>
      <div>
        <div className={headingAndSupportingText}>
          <h1 className={header}>{heading}</h1>
          <p className={paragraph}>{description}</p>
        </div>
        <div className={buttons}>{buttonElements}</div>
      </div>
      <div>{icon}</div>
    </div>
  </main>
);
