import { type Static, type TSchema, Type } from '@sinclair/typebox';

export const Nullable = <T extends TSchema>(schema: T) =>
  Type.Union([schema, Type.Null()]);

export const ReadonlyNullable = <T extends TSchema>(schema: T) =>
  Type.Readonly(Nullable(schema));

export const ReadonlyOptionalNullable = <T extends TSchema>(schema: T) =>
  Type.ReadonlyOptional(Nullable(schema));

export type LocString = Static<typeof LocString>;
export const LocString = Type.Record(
  Type.String(),
  Type.Union([Type.String(), Type.Null(), Type.Undefined()]),
);

export function getFromLocString(
  locStr: LocString | undefined,
  languageCode: string,
): string | undefined {
  if (locStr == null) {
    return undefined;
  }

  const val = locStr[languageCode];
  if (val == null) {
    return undefined;
  } else {
    return val;
  }
}

export interface ExtraInfo {
  version: number;
  was_overruled: boolean;
  was_recategorized?: boolean;
  intended_value: LocString;
  update_value: LocString;
}
