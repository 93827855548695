import { Button } from '@cvpartner/design-system';
import {
  MessageTextSquare02,
  RefreshCcw01,
} from '@cvpartner/design-system/icons/line';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { useRouteError } from 'react-router-dom';
import { getCvPartner } from 'Cvpartner';
import { I18n } from 'util/translations';
import lookoutMan from './lookout-man.svg';
import { errorIcon } from './styles.css';
import { Wrapper } from './wrapper';

const Error: React.VFC = () => (
  <Wrapper
    heading={I18n.t('unexpected_application_error')}
    description={I18n.t('error_message.refresh')}
    buttons={
      <>
        <div>
          <Button
            leadingIcon={RefreshCcw01}
            onPress={() => {
              window.location.reload();
            }}
          >
            {I18n.t('button.refresh_page')}
          </Button>
        </div>
        <div>
          <Button
            hierarchy="secondaryColor"
            leadingIcon={MessageTextSquare02}
            onPress={() => {
              window.Intercom('showNewMessage', 'Hi!');
            }}
          >
            {I18n.t('signin.link_contact_support')}
          </Button>
        </div>
      </>
    }
    icon={<img className={errorIcon} src={lookoutMan} aria-hidden={true} />}
    iconPosition="below"
  />
);

export const RouteError: React.VFC = () => {
  const error = useRouteError();

  React.useEffect(() => {
    if (error != null && typeof error === 'object') {
      getCvPartner().faro?.api.pushError(error as Error);
      Sentry.captureException(error);
    }
  }, [error]);

  return <Error />;
};
