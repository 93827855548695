import classNames from "clsx";
import React from "react";
import { I18n } from "util/translations";

interface TimeoutProps {
  continue_callback: (postponed_expiry_time: boolean) => void;
}

export const Timeout: React.VFC<TimeoutProps> = ({ continue_callback }) => {
  const [postponed_expiry_time, set_postponed_expiry_time] =
    React.useState(false);

  const handle_submit = () => {
    continue_callback(postponed_expiry_time);
  };

  const toggle_postponed_expiry_time = () => {
    set_postponed_expiry_time(!postponed_expiry_time);
  };

  return (
    <div className="modal-wrapper modal-show">
      <div className="popupheading">
        <h1>{I18n.t("soon_logged_out")}</h1>
      </div>
      <div className="modal_content">
        <div className="modal_top_down_padding">
          <div className="input_container">
            <label className="steps left_steps">
              {I18n.t("soon_logged_out_info")}
            </label>
            <ul className="checkbox_list without_margin">
              <li
                onClick={toggle_postponed_expiry_time}
                className={classNames({ selected: postponed_expiry_time })}
              >
                <span>{I18n.t("login_checkbox_after_timeout")}</span>
                <div className="custom_checkbox">
                  <span className="checkmark" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="modal_actions align_wrapper_center">
        <div className="button blue float_right" onClick={handle_submit}>
          {I18n.t("close")}
        </div>
      </div>
    </div>
  );
};
