// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/lib/dashboard_url.js

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export class DashboardUrl {
  static url(selected_tab, proposal_id, user_id, cv_id) {
    if (user_id == null) {
      user_id = false;
    }
    if (cv_id == null) {
      cv_id = false;
    }
    const base_url = `${selected_tab}/proposal/${proposal_id}`;
    if (!user_id || !cv_id) {
      return base_url;
    }
    return `${base_url}/cv/${user_id}/${cv_id}/`;
  }

  static anchored_url(selected_tab, proposal_id, user_id, cv_id) {
    const fragment = DashboardUrl.url(
      selected_tab,
      proposal_id,
      user_id,
      cv_id,
    );

    return `/${fragment}`;
  }

  static proposal_share_url(proposal_id) {
    return `${DashboardUrl.anchored_url("persons", proposal_id)}/share`;
  }

  static reference_url(
    selected_tab,
    proposal_id,
    company_customer_id,
    company_project_id,
    reference_project_id,
    language_code,
  ) {
    const base_url = `${selected_tab}/proposal/${proposal_id}`;
    if (
      !company_customer_id ||
      !company_project_id ||
      !reference_project_id ||
      !language_code
    ) {
      return base_url;
    }
    return `${base_url}/reference_in_proposal/${company_customer_id}/${company_project_id}/${reference_project_id}/${language_code}`;
  }

  static anchored_reference_url(
    selected_tab,
    proposal_id,
    company_customer_id,
    company_project_id,
    reference_project_id,
    language_code,
  ) {
    const fragment = DashboardUrl.reference_url(
      selected_tab,
      proposal_id,
      company_customer_id,
      company_project_id,
      reference_project_id,
      language_code,
    );

    return `/${fragment}`;
  }
}
