import {
  type MetaPage,
  ReactIntegration,
  createReactRouterV6DataOptions,
  getWebInstrumentations,
  initializeFaro,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { matchRoutes } from 'react-router-dom';
import type { Options } from 'types';
import { CVPTraceContextPropagator } from 'util/observability';

export function createFaro(options: Options) {
  const page: MetaPage = {
    url: window.location.href,
    attributes: {
      host: window.location.host,
    },
  };

  const faro = initializeFaro({
    url: '/collect',
    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation({
        contextManager: new ZoneContextManager(),
        propagator: new CVPTraceContextPropagator(),
      }),
      new ReactIntegration({
        router: createReactRouterV6DataOptions({ matchRoutes }),
      }),
    ],
    trackResources: true,
    batching: {
      enabled: true,
      // This is how often we send batches of events to the backend, default is 250ms which results in a lot of requests.
      sendTimeout: 10_000,
      itemLimit: 25,
      paused: false,
    },
    app: {
      name: 'cvpartner-js',
      version: options.release || 'unknown',
      environment: options.environment || 'unknown',
    },
    sessionTracking: {
      enabled: true,
      persistent: true,
    },
    user: {
      id: options.current_user_id || 'anonymous',
    },
    trackWebVitalsAttribution: true,
    pageTracking: { page },
  });

  return faro;
}
