import {
  LifeBuoy01,
  Lock01,
  LogOut01,
  User01,
} from '@cvpartner/design-system/icons/line';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Header, MenuTrigger, Popover } from 'react-aria-components';
import { CurrentUser } from 'stores/CurrentUser';
import { useWillBeLoggedOut } from 'stores/LoggedInStatus';
import { I18n } from 'util/translations';
import { Chevrons, Menu, MenuWrapper, NavLink, Separator } from './MenuNav';
import {
  avatarThumbnail,
  button,
  headerWrapper,
  name,
  roles,
} from './UserNav.css';

const UserThumbnailComponent: React.VFC<{
  isOpen: boolean;
  isHovered: boolean;
}> = ({ isOpen, isHovered }) => {
  const thumbnail = CurrentUser.image?.small_thumb.url;
  const active = isOpen || isHovered;

  if (!thumbnail) {
    return (
      <div className={avatarThumbnail({ active, missingImage: true })}>
        <User01 />
      </div>
    );
  }

  return (
    <img
      src={thumbnail}
      alt="User avatar"
      className={avatarThumbnail({ active: active, missingImage: false })}
      // @ts-expect-error - only supported as React prop in v18, so we're doing lowercase: https://github.com/facebook/react/pull/25927
      fetchpriority="high"
    />
  );
};

const UserThumbnail = observer(UserThumbnailComponent);

const UserNavComponent: React.VFC = () => {
  const [isOpen, setOpen] = React.useState(false);

  const openMenu = React.useCallback(() => {
    setOpen(true);
  }, []);

  const closeMenu = React.useCallback(() => {
    setOpen(false);
  }, []);

  const willBeLoggedOut = useWillBeLoggedOut();

  React.useEffect(() => {
    if (willBeLoggedOut) {
      closeMenu();
    }
  }, [closeMenu, willBeLoggedOut]);

  const menu = (
    <MenuTrigger>
      <Button onPress={openMenu} className={button}>
        {({ isHovered }) => (
          <>
            <UserThumbnail isOpen={isOpen} isHovered={isHovered} />
            <Chevrons isOpen={isOpen} isHovered={isHovered} />
          </>
        )}
      </Button>
      <Popover isOpen={isOpen} onOpenChange={setOpen} placement="bottom right">
        <Menu>
          <Header className={headerWrapper}>
            <div className={name}>{CurrentUser.name}</div>
            <div className={roles}>
              {CurrentUser.displayable_roles_user_names}
            </div>
          </Header>
          {CurrentUser.is_authenticated_by_password ? (
            <>
              <NavLink
                href="/change_password"
                icon={Lock01}
                text={I18n.t('change_password')}
              />
              <Separator />
            </>
          ) : null}
          <NavLink
            href="/settings#/help_guides"
            icon={LifeBuoy01}
            text={I18n.t('settings_labels.guides_and_learning')}
            onAction={closeMenu}
          />
          <Separator />
          <NavLink
            href="/logout"
            icon={LogOut01}
            text={I18n.t('nav.user.sign_out')}
          />
        </Menu>
      </Popover>
    </MenuTrigger>
  );

  if (isOpen) {
    return menu;
  }

  return (
    <MenuWrapper isOpen={isOpen} open={openMenu}>
      {menu}
    </MenuWrapper>
  );
};

export const UserNav = observer(UserNavComponent);
