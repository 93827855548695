import _ from 'underscore';
import { convertHashPath } from 'components/shared/Link';
import { getCvPartner } from '../Cvpartner';

export const navigateTo = async (route: string) => {
  if (!_.isString(route)) {
    return;
  }
  const replacedRoute = convertHashPath(route);

  await getCvPartner().navigate(replacedRoute);
};

export const redirectTo = (path: string) => {
  window.location.href = path;
};
