import { createQueryKeys } from '@lukemorales/query-key-factory';
import { type StaticDecode, Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { http, httpChecked, queryClient } from 'util/fetch';

const baseSchema = Type.Object({
  _id: Type.String(),
  id: Type.String(),
  share_url: Type.String(),
  subdomain: Type.String(),
});
const unactivatedCollaborator = Type.Composite([
  baseSchema,
  Type.Object({ activated: Type.Literal(false) }),
]);
const activatedCollaborator = Type.Composite([
  baseSchema,
  Type.Object({
    activated: Type.Literal(true),
    company_external_collaborations_enabled: Type.Boolean(),
    company_id: Type.String(),
    company_name: Type.String(),
  }),
]);

type UnactivatedCollaborator = StaticDecode<typeof unactivatedCollaborator>;
type ActivatedCollaborator = StaticDecode<typeof activatedCollaborator>;

const collaboratorSchema = Type.Union([
  unactivatedCollaborator,
  activatedCollaborator,
]);

const checker = TypeCompiler.Compile(collaboratorSchema);
const listChecker = TypeCompiler.Compile(Type.Array(collaboratorSchema));

type ExternalCollaborator = UnactivatedCollaborator | ActivatedCollaborator;
export type ExternalCollaborators = readonly ExternalCollaborator[];

function isActivatedCollaborator(
  collaborator: ExternalCollaborator,
): collaborator is ActivatedCollaborator {
  return collaborator.activated;
}

export function getActivatedCollaborators(
  collaborators: ExternalCollaborators,
): readonly ActivatedCollaborator[] {
  return collaborators.filter(isActivatedCollaborator);
}

const queries = createQueryKeys('external_collaborations', {
  fetch: (proposal_id: string) => ({
    queryKey: [proposal_id],
    queryFn: ({ signal }) => {
      const url = `/api/v2/offers/${proposal_id}/external_collaborations`;
      return httpChecked.get(url, listChecker, { signal });
    },
  }),
});

export function useExternalCollaborations(
  proposal_id: string,
): ExternalCollaborators | undefined {
  const { data } = useQuery({
    ...queries.fetch(proposal_id),
    staleTime: 60_000,
  });

  return data;
}

export function useAddExternalCollaborationsToProposal(proposal_id: string) {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (subdomain: string) => {
      const body = { company: { subdomain } };
      const url = `/api/v2/offers/${proposal_id}/external_collaborations`;
      return httpChecked.post(url, checker, { body });
    },
    onSuccess: (collaborator) => {
      const { queryKey } = queries.fetch(proposal_id);

      const existentData =
        queryClient.getQueryData<ExternalCollaborators>(queryKey);

      const newData = existentData
        ? [...existentData, collaborator]
        : [collaborator];

      queryClient.setQueryData(queryKey, newData);
    },
    onError: async () => {
      await invalidateExternalCollaborations(proposal_id);
    },
  });

  return mutate;
}

export function useRemoveExternalCollaborationsFromProposal(
  proposal_id: string,
) {
  const queryClient = useQueryClient();

  const { mutate } = useMutation<
    void,
    unknown,
    string,
    { existentData: ExternalCollaborators | undefined }
  >({
    mutationFn: async (collaboration_id) => {
      const url = `/api/v2/offers/${proposal_id}/external_collaborations/${collaboration_id}`;
      await http.delete(url);
    },
    onMutate: (collaboration_id) => {
      const { queryKey } = queries.fetch(proposal_id);

      const existentData =
        queryClient.getQueryData<ExternalCollaborators>(queryKey);

      // this should always be defined, but TS doesn't know that
      if (existentData) {
        const newData = existentData.filter(
          (collaboration) => collaboration.id !== collaboration_id,
        );

        queryClient.setQueryData(queryKey, newData);
      }

      return { existentData };
    },
    onError: async (_result, _variables, context) => {
      if (context) {
        const { queryKey } = queries.fetch(proposal_id);

        queryClient.setQueryData(queryKey, context.existentData);
      }

      await invalidateExternalCollaborations(proposal_id);
    },
  });

  return mutate;
}

export async function prefetchExternalCollaborations(proposal_id: string) {
  await queryClient.prefetchQuery({
    ...queries.fetch(proposal_id),
    staleTime: 60_000,
  });
}

export async function invalidateExternalCollaborations(proposal_id: string) {
  await queryClient.invalidateQueries(queries.fetch(proposal_id).queryKey);
}
