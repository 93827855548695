import { type Static, Type } from '@sinclair/typebox';

export type Sensitivity = Static<typeof Sensitivity>;
export const Sensitivity = Type.Union([
  Type.Literal('anonymized'),
  Type.Literal('confidential'),
  Type.Literal('real'),
  Type.Null(),
]);

export type EnabledSensitiveFields =
  | 'area'
  | 'customer'
  | 'customer_name'
  | 'customer_description'
  | 'duration'
  | 'extent_currency_amt'
  | 'extent_hours_new'
  | 'location_country_code'
  | 'project_address'
  | 'project_name'
  | 'total_extent_currency_amt'
  | 'total_extent_hours';
