import {
  FaroErrorBoundary,
  withFaroRouterInstrumentation,
} from '@grafana/faro-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FlagProvider, useFlagsStatus } from '@unleash/proxy-client-react';
import { configure } from 'mobx';
import * as React from 'react';
import { I18nProvider } from 'react-aria-components';
import ReactDOM from 'react-dom';
import {
  type NavigateOptions,
  type RouteObject,
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from 'react-router-dom';
import { TimeoutWatcher } from 'auto-converted/lib/TimeoutWatcher';
import { Header } from 'components/header';
import { NavigationBlocker } from 'stores/NetworkState';
import type { Router } from 'types';
import { queryClient } from 'util/fetch';
import { I18n } from 'util/translations';
import { getCvPartner } from '../Cvpartner';

declare module 'react-aria-components' {
  interface RouterConfig {
    routerOptions: NavigateOptions;
  }
}

const isDevelopment = process.env.NODE_ENV === 'development';

if (isDevelopment) {
  configure({
    enforceActions: 'always',
    computedRequiresReaction: true,
    // this is annoying in that there may be _some_ code paths that don't access
    // observables, while others are. The docs even state it's not an issue to
    // overuse `observer`.
    reactionRequiresObservable: false,
    observableRequiresReaction: true,
    disableErrorBoundaries: true,
  });
}

const NothingUntilUnleashReady: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const status = useFlagsStatus();

  if (status.flagsReady || status.flagsError) {
    return children;
  }

  return null;
};

const headerElement = document.querySelector('header#product-header');

const devtoolsDiv = document.createElement('div');
document.body.appendChild(devtoolsDiv);

const languages: Record<string, string> = {
  dk: 'da-DK',
  en: 'en-GB',
  fi: 'fi-FI',
  fr: 'fr-FR',
  no: 'nb-NO',
  se: 'sv-SE',
};

const locale = languages[I18n.locale] ?? I18n.locale;

export function renderReactRoot(
  children: React.ReactElement,
  selector: string,
) {
  const element = document.getElementById(selector);
  const { isLoggedIn, unleashConfig } = getCvPartner();

  ReactDOM.render(
    <React.StrictMode>
      <FaroErrorBoundary>
        <FlagProvider
          config={unleashConfig}
          startClient={unleashConfig != null}
        >
          <QueryClientProvider client={queryClient}>
            <NothingUntilUnleashReady>
              <I18nProvider locale={locale}>
                <>
                  {headerElement &&
                    ReactDOM.createPortal(
                      <Header isLoggedIn={isLoggedIn} />,
                      headerElement,
                    )}
                  {isLoggedIn && <TimeoutWatcher />}
                  {children}
                </>
              </I18nProvider>
            </NothingUntilUnleashReady>
            {ReactDOM.createPortal(<ReactQueryDevtools />, devtoolsDiv)}
          </QueryClientProvider>
        </FlagProvider>
        <NavigationBlocker />
      </FaroErrorBoundary>
    </React.StrictMode>,
    element,
  );
}

export function renderRouter(
  routes: RouteObject[],
  selector: string,
  useBrowserHistoryRouter = false,
): Router {
  const opts: Parameters<typeof createHashRouter>[1] = {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
      // we don't use server rendering, so we don't want any hydration. This
      // prints a warning about missing fallbacks.
      v7_partialHydration: false,
    },
  };
  const router = useBrowserHistoryRouter
    ? createBrowserRouter(routes, opts)
    : createHashRouter(routes, opts);

  const faroRouter = withFaroRouterInstrumentation(router);

  router.subscribe(() => {
    const { faro } = getCvPartner();
    const currentHref = window.location.href;

    if (faro != null && faro.api.getPage()?.url !== currentHref) {
      faro.api.setPage({
        url: currentHref,
        attributes: {
          host: window.location.host,
        },
      });
    }
  });

  renderReactRoot(
    <RouterProvider
      router={faroRouter}
      future={{ v7_startTransition: true }}
    />,
    selector,
  );

  return faroRouter;
}
