import { type StaticDecode, Type } from '@sinclair/typebox';
import { type LocString, Nullable } from './schema';

export interface MongoEntity {
  _id: string;
}

export interface MongoAndPgEntity extends MongoEntity {
  id: string;
}

export interface CustomerContact extends MongoEntity {
  name?: string;
  phone?: string;
  email?: string;
  disabled?: boolean;
  label?: LocString;
  quote?: LocString;
  notes?: LocString;
  quote_approver_name?: string;
  quote_approved_at?: any; // Type needs to be confirmed...
  hide_quote?: boolean;
  contact_details_approver_name?: string;
  contact_details_approved_at?: any; // Type needs to be confirmed...
  hide_contact_details?: boolean;
  name_approver_name?: string;
  name_approved_at?: any; // Type needs to be confirmed...
}

export const ImageUrl = Type.Object({
  url: Type.ReadonlyOptional(Nullable(Type.String())),
});
export const Image = Type.Composite([
  ImageUrl,
  Type.Object({
    thumb: Type.Readonly(ImageUrl),
    fit_thumb: Type.Readonly(ImageUrl),
    large: Type.Readonly(ImageUrl),
    small_thumb: Type.Readonly(ImageUrl),
  }),
]);
export type Image = StaticDecode<typeof Image>;

export interface CustomTagCategory extends MongoEntity {
  values: LocString;
  custom_tags: CustomTag[];
  can_be_used_for_cvs?: boolean;
  can_be_used_for_references?: boolean;
  can_be_used_for_customers?: boolean;
  allow_filtering?: boolean;
  external_unique_id?: string;
}

export interface CustomTag extends MongoEntity {
  values: LocString;
  custom_tag_category_id?: string;
  custom_tag_category?: CustomTagCategory;
  external_unique_id?: string;
  category_ids: string[];
}

export type RangeFilterName =
  | 'AreaRangeFilter'
  | 'ExtentHoursNewRangeFilter'
  | 'ExtentRangeFilter'
  | 'TotalExtentHoursRangeFilter'
  | 'TotalExtentRangeFilter'
  | string; // custom field base range filter

export interface CurrencyAmtRange {
  type: 'currencyAmtRange';
  enabled: boolean;
  name: RangeFilterName;
  label: string;
  amtField: string;
  amtFrom: number | null;
  amtTo: number | null;
  unitField: string;
  unitValue: string;
}

export interface AmtAndUnitRange {
  type: 'unitAmtRange';
  enabled: boolean;
  name: RangeFilterName;
  label: string;
  amtField: string;
  amtFrom: number | null;
  amtTo: number | null;
  unitField: string;
  unitValue: string;
}

export interface AmtRange {
  type: 'amtRange';
  enabled: boolean;
  name: RangeFilterName;
  label: string;
  amtField: string;
  amtFrom: number | null;
  amtTo: number | null;
}

export interface Office extends MongoEntity {
  id: string;
  // not sure if this can actually be null?
  name: string | null;
  selected: boolean;
  default_word_template_id: string | null;
  default_word_json_template_id: string | null;
  default_ppt_template_id: string | null;
  default_indesign_template_id: string | null;
  country_id: string;
  country_code: string;
  override_language_code: string | null;
  num_users: number;
  num_users_activated: number;
  num_users_deactivated: number;
}

export interface Country extends MongoEntity {
  id: string;
  code: string;
  native_language_code: string;
  override_ui_language_code: string | null;
  selected: boolean;
  default_ppt_template_id: null;
  default_word_template_id: null;
  default_word_json_template_id: null;
  default_indesign_template_id: string | null;
  offices: Office[];
  setting: Setting;
}

type Setting = MongoEntity;

export interface CvSearchResult extends MongoEntity {
  user_id: string;
  is_external: boolean;
  image: Image;
  name: string;
  name_multilang: any;
  updated_at: string;
  owner_updated_at: string;
  title: string;
  titles: any;
  telephone: string;
  email: string;
  office_id: string;
  custom_tag_ids: string[];
  country_id: string | null;
}

export interface CvsSearchResults {
  readonly total: number;
  readonly cvs: readonly { cv: CvSearchResult }[];
}

export interface Company extends MongoEntity {
  navn: string;
  name: string;
  created_at: string;
  updated_at: string;
  updated_ago: string;
  cv_template_id: string | null;
  cv_template_type: string | null;
  default_ppt_template_id: string | null;
  default_word_template_id: string | null;
  default_word_json_template_id: string | null;
  default_indesign_template_id: string | null;
  google_apps_enabled: boolean;
  adfs_enabled: boolean;
  password_auth_enabled: boolean;
  part_of_group: boolean;
  setting: Setting;
  language_codes: string[];
  ui_language_codes: string[];
}

// Missing a whole bunch of fields, but fine for now
export interface Template extends MongoEntity {
  type: 'cv' | 'reference_project';
  template_type: 'word' | 'json' | 'indesign';
  name: string;
}
