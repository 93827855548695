import {
  type FC,
  type ReactNode,
  type Reducer,
  createContext,
  useMemo,
  useReducer,
} from 'react';
import _ from 'underscore';
import type {
  Action,
  GroupSearch,
  SearchContext as SearchContextType,
  SearchFilter as SearchFilterType,
  State,
} from './types';

export const InitialSearchFilter: Readonly<SearchFilterType> = {
  type: 'bool',
  value: 'and',
  search_filters: [],
};

export const SearchContext = createContext<SearchContextType>({
  state: createInitialState(),
  dispatch: () => {},
});

type SearchProviderProps = {
  children: ReactNode;
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        [action.payload.searchType]: {
          ...state[action.payload.searchType],
          searchTerm: action.payload.newSearchTerm,
        },
      } satisfies State;
    }
    case 'updateOwned': {
      return {
        ...state,
        reference: {
          ...state.reference,
          filterOwned: action.payload.newOwned,
        },
      } satisfies State;
    }
    case 'updateFilter': {
      return {
        ...state,
        reference: {
          ...state.reference,
          workflowFilter: action.payload.newFilter,
        },
      } satisfies State;
    }
    case 'replaceSearchFilter': {
      return {
        ...state,
        [action.payload.searchType]: {
          ...state[action.payload.searchType],
          searchFiltersList: action.payload.newFilter,
        },
      } satisfies State;
    }
    case 'updatePersonSearchResults': {
      if (
        state.persons.hasSearched &&
        action.payload === state.persons.results
      ) {
        return state;
      }

      return {
        ...state,
        persons: {
          ...state.persons,
          hasSearched: true,
          results: action.payload,
        },
      } satisfies State;
    }
    case 'updateCompanyPersonSearchResults': {
      if (
        state.persons.hasSearched &&
        action.payload === state.persons.companyGroupResults
      ) {
        return state;
      }

      return {
        ...state,
        persons: {
          ...state.persons,
          hasSearched: true,
          companyGroupResults: action.payload,
        },
      } satisfies State;
    }
    case 'updateCvSearchResults': {
      if (state.cvs.hasSearched && action.payload === state.cvs.results) {
        return state;
      }

      return {
        ...state,
        cvs: {
          ...state.cvs,
          hasSearched: true,
          results: action.payload,
        },
      } satisfies State;
    }
    case 'updateCompanyCvSearchSearchResults': {
      if (
        state.cvs.hasSearched &&
        action.payload === state.cvs.groupSearchResults
      ) {
        return state;
      }

      return {
        ...state,
        cvs: {
          ...state.cvs,
          hasSearched: true,
          groupSearchResults: action.payload,
        },
      } satisfies State;
    }
    case 'updateCustomersSearchResults': {
      if (
        state.customers.hasSearched &&
        action.payload === state.customers.results
      ) {
        return state;
      }

      return {
        ...state,
        customers: {
          ...state.customers,
          hasSearched: true,
          results: action.payload,
        },
      } satisfies State;
    }
    case 'updateReferencesSearchResults': {
      if (
        state.reference.hasSearched &&
        action.payload === state.reference.results
      ) {
        return state;
      }

      return {
        ...state,
        reference: {
          ...state.reference,
          hasSearched: true,
          results: action.payload,
        },
      } satisfies State;
    }
    case 'updateReferencesCompanyGroupSearchResults': {
      if (
        state.reference.hasSearched &&
        action.payload === state.reference.groupResults
      ) {
        return state;
      }

      return {
        ...state,
        reference: {
          ...state.reference,
          hasSearched: true,
          groupResults: action.payload,
        },
      } satisfies State;
    }
    case 'setShowAllProposals': {
      return {
        ...state,
        proposals: {
          ...state.proposals,
          showAllProposals: action.payload,
        },
      } satisfies State;
    }
    case 'updateProposalsResult': {
      if (
        state.proposals.hasSearched &&
        action.payload === state.proposals.proposals
      ) {
        return state;
      }

      return {
        ...state,
        proposals: {
          ...state.proposals,
          hasSearched: true,
          proposals: action.payload,
        },
      } satisfies State;
    }
    case 'toggleIncludeArchivedProposals': {
      return {
        ...state,
        proposals: {
          ...state.proposals,
          includeArchived: !state.proposals.includeArchived,
        },
      } satisfies State;
    }
    case 'setGridView': {
      return {
        ...state,
        gridView: action.payload,
      } satisfies State;
    }
    case 'setOrSearch': {
      return {
        ...state,
        [action.payload.searchType]: {
          ...state[action.payload.searchType],
          orSearch: action.payload.value,
        },
      } satisfies State;
    }
    case 'setGroupSearch': {
      return {
        ...state,
        [action.payload.searchType]: {
          ...state[action.payload.searchType],
          showGroupSearch: action.payload.value,
        },
      } satisfies State;
    }
    case 'setSelectedCompanyGroupAssociationId': {
      if (
        state[action.payload.searchType].selectedCompanyGroupAssociationId ===
        action.payload.value
      ) {
        return state;
      }

      return {
        ...state,
        [action.payload.searchType]: {
          ...state[action.payload.searchType],
          selectedCompanyGroupAssociationId: action.payload.value,
          selectedCompanyGroupOfficeIds: [],
          selectedCompanyGroupTagIds: [],
        },
      } satisfies State;
    }
    case 'setSelectedCompanyGroupOfficeIds': {
      if (
        _.isEqual(
          state[action.payload.searchType].selectedCompanyGroupOfficeIds,
          action.payload.value,
        )
      ) {
        return state;
      }

      return {
        ...state,
        [action.payload.searchType]: {
          ...state[action.payload.searchType],
          selectedCompanyGroupOfficeIds: action.payload.value,
        },
      } satisfies State;
    }
    case 'setSelectedCompanyGroupTagIds': {
      if (
        _.isEqual(
          state[action.payload.searchType].selectedCompanyGroupTagIds,
          action.payload.value,
        )
      ) {
        return state;
      }

      return {
        ...state,
        [action.payload.searchType]: {
          ...state[action.payload.searchType],
          selectedCompanyGroupTagIds: action.payload.value,
        },
      } satisfies State;
    }
    case 'setScrollPositionOnNavigate': {
      return {
        ...state,
        scrollPosition: window.scrollY,
      } satisfies State;
    }
    case 'clearSelectedItem': {
      if (state.scrollPosition == null) {
        return state;
      }

      return {
        ...state,
        scrollPosition: null,
      } satisfies State;
    }
    case 'setSelectedOfficeIds': {
      if (_.isEqual(state.selectedOfficeIds, action.payload)) {
        return state;
      }

      return {
        ...state,
        selectedOfficeIds: action.payload,
      } satisfies State;
    }
    case 'setSelectedTagIds': {
      if (_.isEqual(state.selectedTagIds, action.payload)) {
        return state;
      }

      return {
        ...state,
        selectedTagIds: action.payload,
      } satisfies State;
    }
    case 'updateHighlight': {
      const { key, type, searchType } = action.payload;
      const { selectedHighlight } = state[searchType];

      const copy = new Map(selectedHighlight);

      if (copy.get(key) === type) {
        copy.delete(key);
      } else {
        copy.set(key, type);
      }

      return {
        ...state,
        [searchType]: {
          ...state[searchType],
          selectedHighlight: copy,
        },
      } satisfies State;
    }
    case 'setYearsSelected': {
      return {
        ...state,
        reference: {
          ...state.reference,
          selected_years_filter: action.payload,
        },
      } satisfies State;
    }
  }
};

function createInitialState(): State {
  const defaultGroupSearch: GroupSearch = {
    searchTerm: '',
    hasSearched: false,
    showGroupSearch: false,
    selectedCompanyGroupId: undefined,
    selectedCompanyGroupAssociationId: undefined,
    selectedCompanyGroupOfficeIds: [],
    selectedCompanyGroupTagIds: [],
    selectedItem: null,
  };

  return {
    persons: {
      results: { total: 0, cvs: [] },
      companyGroupResults: { total: 0, cvs: [] },
      ...defaultGroupSearch,
    },
    reference: {
      filterOwned: false,
      workflowFilter: 'live',
      searchFiltersList: [InitialSearchFilter],
      orSearch: false,
      results: {
        total: 0,
        references: [],
        workflow_stage_counts: { all: 0, draft: 0, live: 0 },
      },
      groupResults: {
        total: 0,
        references: [],
        workflow_stage_counts: { all: 0, draft: 0, live: 0 },
      },
      selectedHighlight: new Map(),
      selected_years_filter: -1,
      ...defaultGroupSearch,
    },
    customers: {
      searchTerm: '',
      hasSearched: false,
      results: { total: 0, customers: [] },
    },
    cvs: {
      searchFiltersList: [InitialSearchFilter],
      results: { total: 0, cvs: [] },
      groupSearchResults: { total: 0, cvs: [] },
      selectedHighlight: new Map(),
      orSearch: false,
      ...defaultGroupSearch,
    },
    proposals: {
      searchTerm: '',
      hasSearched: false,
      showAllProposals: false,
      proposals: [],
      includeArchived: false,
    },
    selectedOfficeIds: [],
    selectedTagIds: [],
    gridView: true,
    scrollPosition: null,
  };
}

export const SearchProvider: FC<SearchProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, null, createInitialState);

  const memoisedValue = useMemo<SearchContextType>(
    () => ({ state, dispatch }),
    [state],
  );

  return (
    <SearchContext.Provider value={memoisedValue}>
      {children}
    </SearchContext.Provider>
  );
};
