import { type StaticDecode, Type } from '@sinclair/typebox';

export const ProposalStatusSchema = Type.Union([
  Type.Literal('initial'),
  Type.Literal('editing'),
  Type.Literal('finalised'),
  Type.Literal('submitted'),
]);

export type ProposalStatus = StaticDecode<typeof ProposalStatusSchema>;

export const ProposalChildStatusSchema = Type.Union([
  Type.Literal('initial'),
  Type.Literal('editing'),
  Type.Literal('finalised'),
]);
export type ProposalChildStatus = StaticDecode<
  typeof ProposalChildStatusSchema
>;

export const childStatuses: ProposalChildStatus[] = [
  'initial',
  'editing',
  'finalised',
];

export type StatusFor = 'proposal' | 'cv' | 'ref';

const cardMap = new Map<ProposalChildStatus | undefined, string>([
  ['initial', 'proposal_status.list.initial'],
  ['editing', 'proposal_status.list.editing'],
  ['finalised', 'proposal_status.list.finalised'],
  [undefined, 'proposal_status.unknown'],
]);

const listMapOptions: [ProposalStatus, string][] = [
  ['initial', 'proposal_status.cards.initial'],
  ['editing', 'proposal_status.cards.editing'],
  ['finalised', 'proposal_status.cards.finalised'],
  ['submitted', 'proposal_status.cards.submitted'],
];

export const listMap = new Map<ProposalStatus | undefined, string>(
  listMapOptions,
);

export const statusMap = new Map<
  StatusFor,
  Map<ProposalStatus | ProposalChildStatus | undefined, string>
>([
  ['cv', cardMap],
  ['ref', cardMap],
  ['proposal', listMap],
]);

type StatusOption = {
  value: ProposalStatus | 'unknown';
  name: string;
  disabled?: boolean;
};

export type StatusOptions = StatusOption[];
