import { createQueryKeys } from '@lukemorales/query-key-factory';
import { type StaticDecode, Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DashboardUrl as LibDashboardUrl } from 'auto-converted/lib/DashboardUrl';
import { SearchContext } from 'context/SearchContext';
import { Nullable } from 'schema/schema';
import { httpChecked, queryClient } from 'util/fetch';
import { navigateTo } from 'util/routes';
import { ProposalStatusSchema } from '../util/proposalStatus';

const templateExportTypeSchema = Type.Union([
  Type.Literal('word'),
  Type.Literal('json'),
  Type.Literal('indesign'),
]);

const templateDocumentTypeSchema = Type.Union([
  Type.Literal('doc'),
  Type.Literal('ppt'),
  Type.Literal('indesign'),
]);

const proposalResponseSchema = Type.Object({
  _id: Type.String(),
  id: Type.String(),
  navn: Type.String(),
  name: Type.String(),
  archived: Type.Boolean(),
  created_at: Type.String(),
  updated_at: Type.String(),
  updated_ago: Type.String(),
  owner_id: Nullable(Type.String()),
  owner_name: Nullable(Type.String()),
  owner_deactivated: Type.Boolean(),
  api_owner_id: Nullable(Type.String()),
  api_owner_name: Nullable(Type.String()),
  api_owner_deactivated: Type.Boolean(),
  company_id: Type.String(),
  company_name: Type.String(),
  company_external_collaborations_enabled: Type.Boolean(),
  word_template_id: Nullable(Type.String()),
  word_template_company_name: Nullable(Type.String()),
  cv_template_id: Nullable(Type.String()),
  cv_template_type: Nullable(templateExportTypeSchema),
  cv_template_document_type: Nullable(templateDocumentTypeSchema),
  cv_template_company_name: Nullable(Type.String()),
  json_template_id: Nullable(Type.String()),
  json_template_company_id: Nullable(Type.String()),
  json_template_company_name: Nullable(Type.String()),
  json_template_document_type: Nullable(templateDocumentTypeSchema),
  is_docx_compatible: Type.Boolean(),
  is_collaborative: Type.Boolean(),
  status: Nullable(ProposalStatusSchema),
});

const proposalListResponseSchema = Type.Array(proposalResponseSchema);
const proposalResponseChecker = TypeCompiler.Compile(proposalResponseSchema);
const proposalListResponseChecker = TypeCompiler.Compile(
  proposalListResponseSchema,
);

export type Proposal = StaticDecode<typeof proposalResponseSchema>;

type ProposalListOptions = {
  includeArchived?: boolean;
  includeAll?: boolean;
};

const queries = createQueryKeys('proposals', {
  proposal: (proposalId: string) => ({
    queryKey: ['proposal', proposalId],
    queryFn: ({ signal }) =>
      httpChecked.get(`/api/v1/offers/${proposalId}`, proposalResponseChecker, {
        signal,
      }),
  }),
  proposalList: (searchTerm: string, options: ProposalListOptions) => {
    const { includeArchived = false, includeAll = false } = options;
    return {
      queryKey: [searchTerm, { includeArchived, includeAll }],
      queryFn: ({ signal }) => {
        const offerEndpoint = includeAll ? 'all_offers' : 'offers';
        return httpChecked.get(
          `/api/v1/${offerEndpoint}?query=${encodeURIComponent(
            searchTerm,
          )}&include_archived=${includeArchived}`,
          proposalListResponseChecker,
          {
            signal,
          },
        );
      },
    };
  },
});

export const invalidateProposalData = (proposalId: string) => {
  queryClient.invalidateQueries(queries.proposal(proposalId).queryKey);
  queryClient.invalidateQueries({
    queryKey: queries.proposalList._def,
    refetchType: 'active',
  });
};

function updateProposalsCache(updatedProposal: Proposal) {
  queryClient.invalidateQueries({
    queryKey: queries.proposalList._def,
    refetchType: 'active',
  });

  queryClient.setQueryData(
    queries.proposal(updatedProposal.id).queryKey,
    updatedProposal,
  );
}

export type UpdateProposalData = {
  cv_template_id?: string | null;
  cv_template_type?: string | null;
  cv_template_document_type?: string;
  word_template_id?: string;
  json_template_id?: string | null;
  is_collaborative?: boolean;
  name?: string;
  archived?: boolean;
  status?: string;
};

export type UpdateProposalVariables = {
  proposalId: string;
  proposalData: UpdateProposalData;
};

export const useUpdateProposal = () => {
  return useMutation({
    mutationKey: ['proposal', 'update'],
    mutationFn: ({ proposalId, proposalData }: UpdateProposalVariables) =>
      httpChecked.put(`/api/v1/offers/${proposalId}`, proposalResponseChecker, {
        body: { offer: proposalData },
      }),
    onSuccess(updatedProposal) {
      updateProposalsCache(updatedProposal);
    },
  });
};

export const useChangeProposalOwner = () => {
  return useMutation({
    mutationKey: ['proposal', 'change-owner'],
    mutationFn: ({
      proposalId,
      ownerType,
      ownerId,
    }: {
      proposalId: string;
      ownerType: string;
      ownerId: string;
    }) =>
      httpChecked.put(
        `/api/v2/offers/${proposalId}/owner`,
        proposalResponseChecker,
        {
          body: { owner: { type: ownerType, id: ownerId } },
        },
      ),
    onSuccess(updatedProposal) {
      updateProposalsCache(updatedProposal);
    },
  });
};

export type CreateProposalVariables = {
  name: string;
  selectedTab: string;
  navigateToNewProposal?: boolean;
};

export function useCreateProposal() {
  return useMutation({
    mutationFn: (variables: CreateProposalVariables) => {
      return httpChecked.post(`/api/v1/offers`, proposalResponseChecker, {
        body: {
          offer: {
            name: variables.name,
          },
        },
      });
    },
    onSuccess: async (newProposal, variables) => {
      updateProposalsCache(newProposal);
      if (variables.navigateToNewProposal) {
        const url = LibDashboardUrl.anchored_url(
          variables.selectedTab,
          newProposal._id,
        );
        await navigateTo(url);
      }
    },
  });
}

const useUpdateSearchTerm = () => {
  const { dispatch } = useContext(SearchContext);

  return (searchTerm: string) => {
    dispatch({
      type: 'update',
      payload: {
        searchType: 'proposals',
        newSearchTerm: searchTerm,
      },
    });
  };
};

const useShowAllProposals = () => {
  const { dispatch } = useContext(SearchContext);

  return (showAllProposals: boolean) => {
    dispatch({
      type: 'setShowAllProposals',
      payload: showAllProposals,
    });
  };
};

const useToggleIncludeArchived = () => {
  const { dispatch } = useContext(SearchContext);
  return () => {
    dispatch({ type: 'toggleIncludeArchivedProposals' });
  };
};

export const useSearchProposals = (
  searchTerm: string,
  options: ProposalListOptions,
) => {
  const { data: proposals } = useQuery({
    ...queries.proposalList(searchTerm, options),
    staleTime: 60_000,
    keepPreviousData: true,
  });

  return proposals ?? [];
};

export const useProposals = () => {
  const {
    state: {
      proposals: { searchTerm, includeArchived, showAllProposals },
    },
  } = useContext(SearchContext);
  const onChangeShowAllProposals = useShowAllProposals();
  const onToggleIncludeArchived = useToggleIncludeArchived();
  const onUpdateSearchTerm = useUpdateSearchTerm();

  const {
    data: proposals,
    isLoading,
    isFetching,
  } = useQuery({
    ...queries.proposalList(searchTerm, {
      includeArchived,
      includeAll: showAllProposals,
    }),
    staleTime: 60_000,
    keepPreviousData: true,
  });

  return {
    proposals: proposals ?? [],
    isLoadingProposals: isLoading || isFetching,
    showAllProposals,
    updateShowAllProposals: onChangeShowAllProposals,
    includeArchived,
    toggleIncludeArchived: onToggleIncludeArchived,
    searchTerm,
    updateSearchTerm: onUpdateSearchTerm,
  };
};
