import {
  CodeSnippet01,
  FileCheck02,
  FileSearch02,
  FolderCheck,
  LifeBuoy01,
  Settings01,
  Settings04,
  Stars02,
  Table,
  UploadCloud01,
  Users01,
} from '@cvpartner/design-system/icons/line';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, MenuTrigger, Popover } from 'react-aria-components';
import { useIsAuditLogEnabled } from 'stores/Addons';
import { CurrentUser } from 'stores/CurrentUser';
import { useWillBeLoggedOut } from 'stores/LoggedInStatus';
import { I18n } from 'util/translations';
import { Chevrons, Menu, MenuWrapper, NavLink, Separator } from './MenuNav';
import { anchor, icon } from './NavItem.css';

const { pathname } = window.location;

const AdminNavComponent: React.VFC<{ showLinkText: boolean }> = ({
  showLinkText,
}) => {
  const auditLogEnabled = useIsAuditLogEnabled();

  const [isOpen, setOpen] = React.useState(false);

  const active = pathname === '/tools' || pathname === '/settings';

  const open = React.useCallback(() => {
    setOpen(true);
  }, []);
  const close = React.useCallback(() => {
    setOpen(false);
  }, []);

  const willBeLoggedOut = useWillBeLoggedOut();

  React.useEffect(() => {
    if (willBeLoggedOut) {
      close();
    }
  }, [close, willBeLoggedOut]);

  const menu = (
    <MenuTrigger>
      <Button
        onPress={open}
        className={({ isHovered }) =>
          anchor({
            active,
            iconOnly: !showLinkText,
            isHovered: isHovered || isOpen,
            asButton: true,
          })
        }
      >
        {({ isHovered }) => (
          <>
            <Settings01
              className={icon({
                active: active || isOpen,
                isHovered: isHovered || isOpen,
              })}
            />
            {showLinkText ? I18n.t('application_navigation.admin') : null}
            <Chevrons isOpen={isOpen} isHovered={isHovered} />
          </>
        )}
      </Button>
      <Popover isOpen={isOpen} onOpenChange={setOpen} placement="bottom right">
        <Menu>
          <NavLink
            href="/settings#/"
            icon={Settings01}
            text={I18n.t('settings')}
            onAction={close}
          />
          <Separator />
          <NavLink
            href="/settings#/templates"
            icon={FolderCheck}
            text={I18n.t('settings_labels.templates_heading')}
            onAction={close}
          />
          <Separator />
          <NavLink
            href="/settings#/users"
            icon={Users01}
            text={I18n.t('Users')}
            onAction={close}
          />
          <Separator />
          {CurrentUser.is_internationalmanager ? (
            <>
              <NavLink
                href="/settings#/customize_interface"
                icon={Settings04}
                text={I18n.t('settings_labels.customize_interface')}
                onAction={close}
              />
              <Separator />
              <NavLink
                href="/settings#/compliance"
                icon={FileCheck02}
                text={I18n.t('settings_labels.compliance')}
                onAction={close}
              />
              <Separator />
              <NavLink
                href="/settings#/data_import"
                icon={UploadCloud01}
                text={I18n.t('settings_labels.data_import.data_import')}
                onAction={close}
              />
              <Separator />
              <NavLink
                href="/tools#/masterdata"
                icon={Table}
                text={I18n.t('settings_labels.masterdata')}
                onAction={close}
              />
              <Separator />
            </>
          ) : null}
          <NavLink
            href="/settings#/apikeys"
            icon={CodeSnippet01}
            text={I18n.t('settings_labels.api_keys')}
            onAction={close}
          />
          <Separator />
          {CurrentUser.is_internationalmanager ? (
            <>
              <NavLink
                href="/settings#/ai"
                icon={Stars02}
                text={I18n.t('settings_labels.ai_generated_content')}
                onAction={close}
              />
              <Separator />
            </>
          ) : null}
          {auditLogEnabled && CurrentUser.is_internationalmanager ? (
            <>
              <NavLink
                href="/settings#/audit_log"
                icon={FileSearch02}
                text={I18n.t('settings_labels.audit_log')}
                onAction={close}
              />
              <Separator />
            </>
          ) : null}
          <NavLink
            href="/settings#/help_guides"
            icon={LifeBuoy01}
            text={I18n.t('settings_labels.guides_and_learning')}
            onAction={close}
          />
        </Menu>
      </Popover>
    </MenuTrigger>
  );

  if (isOpen) {
    return (
      <>
        <Separator orientation="vertical" />
        {menu}
      </>
    );
  }

  return (
    <>
      <Separator orientation="vertical" />
      <MenuWrapper isOpen={isOpen} open={open}>
        {menu}
      </MenuWrapper>
    </>
  );
};

export const AdminNav = observer(AdminNavComponent);
