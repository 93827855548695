import { type StaticDecode, Type } from '@sinclair/typebox';
import {
  type LocString,
  ReadonlyNullable,
  ReadonlyOptionalNullable,
} from './schema';
import { Image } from './shared';

export const Role = Type.Union([
  Type.Literal('external'),
  Type.Literal('limited_access'),
  Type.Literal('consultant'),
  Type.Literal('countrymanager'),
  Type.Literal('referencemanager'),
  Type.Literal('internationalmanager'),
  Type.Literal('departmentmanager'),
  // the below are for API users
  Type.Literal('proposal'),
  Type.Literal('readonly_consultant'),
  Type.Literal('readonly_countrymanager'),
  Type.Literal('readonly_internationalmanager'),
  Type.Literal('readonly_proposal'),
]);
export type Role = StaticDecode<typeof Role>;

const BaseUser = Type.Object({
  _id: Type.Readonly(Type.String()),
  id: Type.Readonly(Type.String()),
  company_id: Type.Readonly(Type.String()),
  office_id: Type.Readonly(Type.String()),
  country_id: Type.Readonly(Type.String()),
  country_code: Type.Readonly(Type.String()),
  role: Type.Readonly(Role),
  roles: Type.Readonly(Type.Array(Role)),
  created_at: Type.Readonly(Type.String()),
});

// TODO: add missing fields, and replace `User`
export const UserSubset = Type.Composite([
  BaseUser,
  Type.Object({
    user_id: Type.Readonly(Type.String()),
    name: Type.Readonly(Type.String()),
    external_unique_id: ReadonlyNullable(Type.String()),
    language_code: Type.Readonly(Type.String()),
    language_codes: Type.Readonly(Type.Array(Type.String())),
    ui_language_code: Type.Readonly(Type.String()),
    masterdata_languages: Type.Readonly(Type.Array(Type.String())),
    override_language_code: ReadonlyNullable(Type.String()),
    default_cv_id: ReadonlyNullable(Type.String()),
    default_cv_template_id: ReadonlyNullable(Type.String()),
    deactivated: Type.Readonly(Type.Boolean()),
    image: Type.Readonly(Image),
    is_authenticated_by_password: Type.Readonly(Type.Boolean()),
  }),
]);
export type UserSubset = StaticDecode<typeof UserSubset>;

export interface User extends UserSubset {
  selected_office_ids: readonly string[];
  selected_tag_ids: readonly string[];
  include_officeless_reference_projects: boolean;
  expand_proposals_toggle: boolean;
  company_group_ids: readonly string[];
  role_allowed_office_ids: readonly string[];
  role_allowed_tag_ids: readonly string[];
  company_subdomains: readonly string[];
  company_name: string;
  email: string;
  upn: string | null;
  deactivated_at: boolean;
  updated_at: string;
  office_name: string;
  title: LocString;
  international_toggle: 'expand' | 'hide';
  preferred_download_format: string;
  telephone: any;
}

export const ApiUser = Type.Composite([
  BaseUser,
  Type.Object({
    name: ReadonlyOptionalNullable(Type.String()),
    technical_contact: ReadonlyOptionalNullable(Type.String()),
    access_token: Type.Readonly(Type.String()),
    enabled: Type.Readonly(Type.Boolean()),
    created_by: ReadonlyOptionalNullable(Type.String()),
  }),
]);
export type ApiUser = StaticDecode<typeof ApiUser>;

export type UserOrApiUser = User | ApiUser;

export function isApiUser(user: UserOrApiUser): user is ApiUser {
  return typeof (user as ApiUser).access_token === 'string';
}
