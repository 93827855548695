import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { PusherActions } from 'actions/actions';
import type { CvMetadata } from 'schema/cv';
import { http, queryClient } from 'util/fetch';

const queries = createQueryKeys('cv-metadata', {
  fetch: (user_id: string, cv_id: string) => ({
    queryKey: [{ user_id, cv_id }],
    queryFn: async ({ signal }) => {
      const metadata = await http.get<CvMetadata>(
        `/api/v3/cvs/${user_id}/${cv_id}/metadata`,
        { signal },
      );

      await PusherActions.subscribe(user_id, cv_id);

      return metadata;
    },
  }),
});

export function useCvMetadata(user_id: string, cv_id: string) {
  const { data } = useQuery({
    ...queries.fetch(user_id, cv_id),
    staleTime: 60_000,
    refetchInterval: 60_000,
  });

  return data;
}

export async function prefetchCvMetadata(user_id: string, cv_id: string) {
  await queryClient.prefetchQuery({
    ...queries.fetch(user_id, cv_id),
    staleTime: 60_000,
  });
}

export async function invalidateCvMetadata(user_id: string, cv_id: string) {
  await queryClient.invalidateQueries(queries.fetch(user_id, cv_id).queryKey);
}
