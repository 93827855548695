import { ChevronDown, ChevronUp } from '@cvpartner/design-system/icons/line';
import type * as React from 'react';
import {
  MenuItem,
  Menu as ReactAriaMenu,
  Separator as ReactAriaSeparator,
  type SeparatorProps,
} from 'react-aria-components';
import {
  anchor,
  anchorText,
  chevron,
  icon,
  link,
  menuWrapper,
  popover,
  separator,
} from './MenuNav.css';

export const NavLink: React.VFC<{
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  text: string;
  onAction?: () => void;
}> = ({ href, icon: Icon, text, onAction }) => (
  <MenuItem href={href} className={link} onAction={onAction}>
    {({ isHovered, isFocused }) => (
      <div className={anchor({ isHovered: isHovered || isFocused })}>
        <Icon className={icon} />
        <span className={anchorText}>{text}</span>
      </div>
    )}
  </MenuItem>
);

export const Menu: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => <ReactAriaMenu className={popover}>{children}</ReactAriaMenu>;

export const Separator: React.VFC<{
  orientation?: SeparatorProps['orientation'];
}> = ({ orientation = 'horizontal' }) => (
  <ReactAriaSeparator
    className={separator({ orientation })}
    orientation={orientation}
  />
);

export const Chevrons: React.VFC<{ isHovered: boolean; isOpen: boolean }> = ({
  isHovered,
  isOpen,
}) =>
  isOpen ? (
    <ChevronUp className={chevron({ isHovered, active: true })} />
  ) : (
    <ChevronDown className={chevron({ isHovered })} />
  );

export const MenuWrapper: React.VFC<{
  isOpen: boolean;
  open: () => void;
  children: React.ReactNode;
}> = ({ isOpen, open, children }) => (
  <div className={menuWrapper} onClickCapture={isOpen ? undefined : open}>
    {children}
  </div>
);
